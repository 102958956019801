




































import * as echarts from "echarts/core";
import { PieChart, LineChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    GraphicComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    PieChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    GraphicComponent,
]);

import { Component, Prop, Vue } from "vue-property-decorator";

import LsDialog from "@/components/ls-dialog.vue";

@Component({
    components: {
        LsDialog,
    },
})
export default class GoodsIndex extends Vue {
    now_time: any = "";
    value = "3";
    options = [
        {
            value: "1",
            label: "日粒度",
        },
        {
            value: "2",
            label: "周粒度",
        },
        {
            value: "3",
            label: "月粒度",
        },
    ];
    platformForGoodsEchart() {
        let myChart = echarts.init(this.$refs.platformForGoods as any);
        myChart.setOption({
            title: {
                text: "数据变化情况",
            },
              color: ['#fc8251', '#5470c6', '#91cd77', '#ef6567', '#f9c956', '#75bedc'],
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                    label: {
                        backgroundColor: "#6a7985",
                    },
                },
            },
            legend: {
                data: [
                    "数据变更情况",
                    "价格变动数量",
                    "策略上架",
                    "维护上架",
                    "策略下架",
                    "供应链下架",
                ],
            },
            toolbox: {
                feature: {
                    saveAsImage: {},
                },
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    boundaryGap: false,
                    data: [
                        "2022/12/7",
                        "2022/12/8",
                        "2022/12/9",
                        "2022/12/10",
                        "2022/12/11",
                        "2022/12/12",
                        "2022/12/13",
                    ],
                },
            ],
            yAxis: [
                {
                    type: "value",
                },
            ],
            series: [
                {
                    name: "策略上架",
                    type: "line",
                    smooth: true,
                    stack: "Total",
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: [120, 132, 101, 134, 90, 230, 210],
                },
                {
                    name: "维护上架",
                    type: "line",
                    smooth: true,
                    stack: "Total",
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: [220, 182, 191, 234, 290, 330, 310],
                },
                {
                    name: "策略下架",
                    type: "line",
                    smooth: true,
                    stack: "Total",
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: [150, 232, 201, 154, 190, 330, 410],
                },
                {
                    name: "供应链下架",
                    type: "line",
                    smooth: true,
                    stack: "Total",
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: [150, 232, 201, 154, 190, 330, 410],
                },
                {
                    name: "数据变更情况",
                    type: "line",
                    smooth: true,
                    stack: "Total",
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: [320, 332, 301, 334, 390, 330, 320],
                },
                {
                    name: "价格变动数量",
                    type: "line",
                    smooth: true,
                    stack: "Total",
                    label: {
                        show: true,
                        position: "top",
                    },
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                },
            ],
        });
    }
    inMallEchart() {
        let myChart = echarts.init(this.$refs.inMall as any);
        myChart.setOption({
            title: {
                text: "商品包总数量",
            },
            color: [
                "#75bedc",
                "#91cd77",
            ],
            tooltip: {
                trigger: "item",
            },
            legend: {
                data: ["总台商品占比", "自有供应链商品占比"],
                right: "right",
            },

            graphic: [
                {
                    //图形中间文字
                    type: "group",
                    left: "center",
                    top: "center",
                    children: [
                        {
                            type: "text",
                            id: "rect1",
                            style: {
                                text: "商品总数",
                                textAlign: "center",
                                fill: "#010101",
                                fontSize: 18,
                            },
                        },
                        {
                            type: "text",
                            id: "rect2",
                            style: {
                                text: "1783",
                                textAlign: "center",
                                fill: "#010101",
                                fontSize: 28,
                                // x: 10,
                                y: 25,
                            },
                        },
                    ],
                },
            ],
            series: [
                {
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        formatter: "  {b|{b}：}\n {b|{c}} {per|{d}%}  ",
                        backgroundColor: "#F6F8FC",
                        borderColor: "#8C8D8E",
                        borderWidth: 1,
                        borderRadius: 4,
                        rich: {
                            a: {
                                color: "#6E7079",
                                lineHeight: 22,
                                align: "center",
                            },
                            hr: {
                                borderColor: "#8C8D8E",
                                width: "100%",
                                borderWidth: 1,
                                height: 0,
                            },
                            b: {
                                color: "#4C5058",
                                fontSize: 14,
                                fontWeight: "bold",
                                lineHeight: 33,
                            },
                            per: {
                                color: "#fff",
                                backgroundColor: "#4C5058",
                                padding: [3, 4],
                                borderRadius: 4,
                            },
                        },
                    },
                    labelLine: {
                        length: 10,
                    },
                    data: [
                        { value: 1048, name: "总台商品占比" },
                        { value: 735, name: "自有供应链商品占比" },
                    ],
                },
            ],
        });
    }

    created() {
        let time = new Date();
        let month = time.getMonth() + 1;
        this.now_time = time.getFullYear() + "/" + month + "/" + time.getDate();
    }

    mounted() {
        this.platformForGoodsEchart();
        this.inMallEchart();
    }
}
